<template>
    <section>
        <div class="text-center text-general-red">
            <h1>Respuesta Transaccion</h1>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="col-8 border d-middle py-2">
                <div class="pl-2 border-right f-500 text-general col-4">
                    Estado transaccion
                </div>
                <div class=" col-4">
                    {{ transactionState }}
                </div>
            </div>
            <div class="col-8 border d-middle py-2">
                <div class="pl-2 border-right f-500 text-general col-4">
                    Fecha transaccion
                </div>
                <div class=" col-4">
                    {{ datos.processingDate }}
                </div>
            </div>
            <div class="col-8 border d-middle py-2">
                <div class="pl-2 border-right f-500 text-general col-4">
                    Referencia
                </div>
                <div class=" col-4">
                    {{ datos.referenceCode }}
                </div>
            </div>
            <div class="col-8 border d-middle py-2">
                <div class="pl-2 border-right f-500 text-general col-4">
                    Mensaje
                </div>
                <div class=" col-4">
                    {{ datos.message }}
                </div>
            </div>
            <div class="col-8 border d-middle py-2">
                <div class="pl-2 border-right f-500 text-general col-4">
                    Valor
                </div>
                <div class=" col-4">
                    {{ datos.TX_VALUE }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    data(){
        return{
            datos:{}
        }
    },
    computed:{
        transactionState(){
            if(this.datos.transactionState == 4) return 'APROVADO'
            if(this.datos.transactionState == 6) return 'RECHAZADO'
            if(this.datos.transactionState == 104) return 'ERROR'
            if(this.datos.transactionState == 5) return 'EXPIRADA'
            if(this.datos.transactionState == 7) return 'PENDIENTE'
            return 'SIN ESTADO'
        }
    },
    mounted(){
        this.datos = this.$route.query
    },
    methods: {

    }
}
</script>
